import React from "react";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import Button from "@material-ui/core/Button";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { selectLanguage } from "../modules/translation/slice";
import { FWD_FONT } from "../config";

export default function ChangeLang() {
    const dispatch = useDispatch();
    const matches = useMediaQuery("(min-width:600px)");
    const i18n = useTranslation().i18n;

    const changeLanguage = (lng) => {
        i18n.changeLanguage(lng);
    };

    return (
        <div
            style={{
                position: "fixed",
                display: "flex",
                top: "20px",
                right: "20px",
                fontSize: "18px",
            }}>
            <ButtonGroup
                disableElevation
                variant="contained"
                color="primary"
                size={matches ? "medium" : "small"}>
                <Button
                    onClick={() => {
                        changeLanguage("kh");
                        dispatch(selectLanguage("kh"));
                    }}>
                    <div style={{ fontFamily: FWD_FONT.BOOK }}>kh</div>
                </Button>
                <Button
                    onClick={() => {
                        changeLanguage("en_kh");
                        dispatch(selectLanguage("en_kh"));
                    }}>
                    <div style={{ fontFamily: FWD_FONT.BOOK }}>en</div>
                </Button>
            </ButtonGroup>
        </div>
    );
}
