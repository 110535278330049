import { doFormPost, doJsonPost } from "../../utils/Network";
import API from "../../utils/API";

export async function Login(username, password) {
    const res = await doFormPost(
        API.login,
        {},
        { username, password, grant_type: "password" },
    );
    const token = res.access_token;

    if (!token) {
        return null;
    }
    return token;
}

//Reset password async
export async function RequestUsernames(phoneNumber) {
    const body = {
        mobileNumber: phoneNumber,
        agentEmail: "",
    };

    const header = {};
    const result = await doJsonPost(
        API.forgetPw.getUsernameByMobileNo,
        header,
        body,
    );
    return result;
}

export async function sendSMS(username) {
    const body = {
        username,
        factorType: "SMS",
    };
    const result = await doJsonPost(API.forgetPw.sendOTPByUsername, {}, body);
    return result;
}

export async function verifySMSFactor({ stateToken, passCode }) {
    const body = {
        stateToken,
        passCode,
    };
    const result = await doJsonPost(API.forgetPw.verifyOTP, {}, body);
    return result;
}

export async function resetPw({ stateToken, newPassword }) {
    const body = {
        stateToken,
        newPassword,
    };
    const result = await doJsonPost(API.forgetPw.resetPassword, {}, body);
    return result;
}
