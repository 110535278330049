import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
    sendSMS,
    smsChallenge,
    retrieveFactorId,
    smsChallengeVerify,
} from "./api";
const initialState = {
    userId: "",
    factorId: "",
    error: "",
    phoneNumber: "",
    state: "not submitted",
    factorResult: "",
};

export const retrieveFactorIdAsync = createAsyncThunk(
    "reg/retrieveFactorId",
    async (userId) => {
        const factorIdRes = await retrieveFactorId(userId);

        const factorId = factorIdRes?.[0].id;

        if (!factorId) {
            return { errorSummary: "No factor available" };
        }

        const smsRes = await smsChallenge(userId, factorId);
        return {
            factorId,
            userId,
            phoneNumber: smsRes.profile.phoneNumber,
            smsChallenge: true,
        };
    },
);

export const smsChallengeVerifyAsync = createAsyncThunk(
    "reg/smsChallengeVerify",
    async (passCode, thunkAPI) => {
        const { userId, factorId } = thunkAPI.getState().reg;
        const result = await smsChallengeVerify(userId, factorId, passCode);

        if (result?.errorSummary) {
            return { errorSummary: result.errorSummary };
        }

        return result;
    },
);

export const getFactorId = createAsyncThunk(
    "reg/getFactorId",
    async (userId) => {
        const result = await sendSMS(userId);
        return result;
    },
);

export const regSlice = createSlice({
    name: "reg",
    initialState,
    reducers: {
        getUserId: (state, action) => {
            state.userId = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getFactorId.pending, (state) => {
                state.state = "loading";
            })
            .addCase(getFactorId.fulfilled, (state, action) => {
                state.state = "fulfilled";
                const errorSummary = action.payload?.errorSummary;
                const factorId = action.payload?.id;
                let phoneNumber = action.payload?.profile?.phoneNumber;
                if (errorSummary) {
                    state.error = errorSummary;
                }
                if (factorId) {
                    state.error = "";
                    state.factorId = factorId;
                    phoneNumber = phoneNumber.slice(0, 8) + "xxxx";
                    state.phoneNumber = phoneNumber;
                }
                return state;
            })
            .addCase(retrieveFactorIdAsync.pending, (state) => {
                state.state = "loading";
            })
            .addCase(retrieveFactorIdAsync.fulfilled, (state, action) => {
                state.state = "idle";
                const errorSummary = action.payload?.errorSummary;
                const factorId = action.payload?.factorId;
                const userId = action.payload?.userId;
                const phoneNumber = action.payload?.phoneNumber;
                if (errorSummary) {
                    state.error = errorSummary;
                }
                if (factorId) {
                    state.error = "";
                    state.factorId = factorId;
                    state.userId = userId;
                    state.phoneNumber = phoneNumber;
                }
                return state;
            })
            .addCase(smsChallengeVerifyAsync.pending, (state) => {
                state.state = "loading";
            })
            .addCase(smsChallengeVerifyAsync.fulfilled, (state, action) => {
                state.state = "idle";
                const errorSummary = action.payload?.errorSummary;
                const factorResult = action.payload?.factorResult;

                if (errorSummary) {
                    state.error = errorSummary;
                }
                if (factorResult) {
                    state.factorResult = factorResult;
                }
                return state;
            });
    },
});

export const { getUserId } = regSlice.actions;
export default regSlice.reducer;
