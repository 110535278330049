import { formStyles } from "./style/formStyle";
import CircularProgress from "@material-ui/core/CircularProgress";

const Loading = ({ msg }) => {
    const formStyle = formStyles();
    const style = {
        backgroundColor: "white",
        opacity: 0.9,
        position: "fixed",
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        zIndex: 99,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
    };

    return (
        <div style={style}>
            {msg && (
                <div
                    className={formStyle.forgotPW}
                    style={{ marginBottom: "20px" }}>
                    {msg}
                </div>
            )}
            <CircularProgress />
        </div>
    );
};

export default Loading;
