import React from "react";
import { useTranslation } from "react-i18next";
import LoginPage from "./pages/entry/LoginPage";
import PageRouter from "./pages/entry/PageRouter";
import { useSelector } from "react-redux";
import { AuthSelector } from "./modules/auth/";
import { useLocation } from "react-router-dom";
import { useEffect } from "react";
import firebase from "./firebase";
import PageContainer from "./components/container/PageContainer";
import { LangSelector } from "./modules/translation";
import GlobalStyle from "./globalStyle";

const analytics = firebase.analytics();

const App = () => {
    const authSelector = AuthSelector.selectToken;
    const isLogin = useSelector(authSelector);
    const i18n = useTranslation().i18n;
    const langSelector = LangSelector.selectLanguage;
    const currentLanguage = useSelector(langSelector);
    const changeLanguage = (lng) => {
        i18n.changeLanguage(lng);
    };
    useEffect(() => {
        changeLanguage(currentLanguage);
        // eslint-disable-next-line
    }, []);

    const t = useTranslation().t;

    let location = useLocation();

    useEffect(() => {
        const page_path = location.pathname + location.search;
        console.log("firebase", firebase);
        analytics.setCurrentScreen(page_path);
        analytics.logEvent("page_view", { page_path });
    }, [location]);
    console.log(currentLanguage);

    return (
        <>
            <GlobalStyle lng={currentLanguage} />
            <PageContainer>
                <div style={{ height: "100%", width: "100%" }}>
                    {isLogin ? <PageRouter t={t} /> : <LoginPage t={t} />}
                </div>
            </PageContainer>
        </>
    );
};

export default App;
