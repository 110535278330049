import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Div } from "../../components";
import { styled } from "@material-ui/core/styles";
import AppsModal from "./Apps/AppsModal";
import NavBar from "../../components/NavBar/NavBar";
import {
    selectApps,
    fetchAppsAsync,
} from "../../modules/appsCenter/appsCenterSlice";
import CircularProgress from "@material-ui/core/CircularProgress";
import scrollToTop from "../../utils/ScrollToTop";

const Dashboard = ({ t }) => {
    const apps = useSelector(selectApps);
    const appsLoading = useSelector((state) => state.appsCenter.status);

    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(fetchAppsAsync());
        // eslint-disable-next-line
    }, []);

    //Fixing some browser behavior of position fixed nav bar
    scrollToTop();

    return (
        <div style={{ width: "100vw", height: "100%" }}>
            <NavBar />
            <DashboardBackground>
                {appsLoading === "loading" && (
                    <CircularProgress
                        style={{ position: "absolute", top: "50vh" }}
                    />
                )}
                <AppContainer>
                    {apps?.map((app) => {
                        return (
                            <AppDiv key={`app_${app.id}`}>
                                <AppsModal
                                    t={t}
                                    img={app.img}
                                    id={app.id}
                                    name={app.name}
                                    content={app.content}
                                    iosLink={app.iosLink}
                                    androidLink={app.androidLink}
                                />
                            </AppDiv>
                        );
                    })}
                </AppContainer>
            </DashboardBackground>
        </div>
    );
};

export default Dashboard;

const DashboardBackground = styled(Div)({
    paddingTop: "12vh",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    backgroundSize: "cover",
    overflow: "auto",
});

const AppContainer = styled(Div)({
    paddingLeft: "5vw",
    paddingRight: "5vw",
    width: "100%",
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "flex-start",
});

const AppDiv = styled(Div)({
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
    height: "22.5vw",
    width: "22.5vw",
});
