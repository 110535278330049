import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { registerAccount } from "./api";

const initialState = {
    registration: {},
};

export const doRegistration = createAsyncThunk(
    "register/doRegistration",
    async () => {
        const response = await registerAccount();
        // The value we return becomes the `fulfilled` action payload
        return response.data;
    },
);

export const registerSlice = createSlice({
    name: "register",
    initialState,
    // The `reducers` field lets us define reducers and generate associated actions
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(doRegistration.pending, (state) => {
                state.status = "loading";
            })
            .addCase(doRegistration.fulfilled, (state, action) => {
                state.registration = action.payload;
                state.status = "completed";
            });
    },
});

// export const { getLeads } = registerSlice.actions;

export default registerSlice.reducer;
