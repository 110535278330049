import React from "react";
import { useHistory } from "react-router-dom";

export default function NoMatch() {
    const history = useHistory();

    return (
        <div>
            404 Not found{" "}
            <button
                onClick={() => {
                    history.push("/");
                }}>
                Back to home
            </button>
        </div>
    );
}
