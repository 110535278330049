import React from "react";
import { Route, Switch } from "react-router-dom";
import Dashboard from "../home/Dashboard";
import NoMatch from "./NoMatch";
import LogoutToRegister from "./LogoutToRegister";

function PageRouter({ t }) {
    return (
        <>
            <div>
                <Switch>
                    <Route path="/home/dashboard">
                        <Dashboard t={t} />
                    </Route>
                    <Route exact path="/">
                        <Dashboard t={t} />
                    </Route>
                    <Route path="/register">
                        <LogoutToRegister />
                    </Route>
                    <Route>
                        <NoMatch />
                    </Route>
                </Switch>
            </div>
        </>
    );
}

export default PageRouter;
