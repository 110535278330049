import API from "../../utils/API";
import { doJsonPost } from "../../utils/Network";

export function registerAccount() {
    //TODO: change the body later
    const body = {
        profile: {
            login: "A00007",
            employeeNumber: "234432",
            userType: "Agent",
            firstName: "Call Me",
            lastName: "L",
            displayName: "Agent L",
            email: "agent.L@gmail.com",
            organization: "Agency 1",
            countryCode: "KH",
        },
    };
    return doJsonPost(API.registerAccount, {}, body);
}
