import { Global, css } from "@emotion/react";
import { FWD_FONT, FWD_KH_FONT } from "./config";

const GlobalStyle = ({ lng }) => (
    <Global
        styles={css`
            html,
            body {
                font-family: ${lng === "kh" || !lng
                    ? FWD_KH_FONT.REGULAR
                    : FWD_FONT.BOOK};
                font-size: 14px;
                display: flex;
                margin: 0;
                height: 100vh;
                width: 100vw;
                overflow-x: hidden;
                overflow-y: auto;
                background: #f0f0f0f0;
                span.MuiButton-label {
                    font-family: ${lng === "kh" || !lng
                        ? FWD_KH_FONT.REGULAR
                        : FWD_FONT.BOOK};
                }
            }
        `}
    />
);

export default GlobalStyle;
