import React, { useState } from "react";
import {
    Div,
    LoginTitle,
    LoginForm,
    ForgetPWForm,
    RegForm,
    ChangePwForm,
    SetPwForm,
} from "../../components";
import { useSelector, useDispatch } from "react-redux";
import { fwd_logo, FWD } from "../../assets/images/index";
import { styled } from "@material-ui/core/styles";
import { Route, Switch } from "react-router-dom";
import { AuthSlice } from "../../modules/auth/";
import SelectUserForm from "../../components/SelectUserForm";
import NoMatch from "./NoMatch";
import { LangSelector } from "../../modules/translation";
import ChangeLang from "../../components/ChangeLang";

const LoginPage = ({ t }) => {
    const [page, switchPage] = useState("login");
    const dispatch = useDispatch();
    const langSelector = LangSelector.selectLanguage;
    const currentLanguage = useSelector(langSelector);

    const { clearResetPwState } = AuthSlice;

    const LogoWrap = styled(Div)({
        paddingTop: "20px",
        paddingBottom: "20px",
        background: "white",
        width: "100%",
    });
    const LoginBackground = styled(Div)({
        backgroundColor: "#e87824",
        width: "100%",
        flexGrow: 1,
        maxWidth: "100%",
        backgroundSize: "cover",
        backgroundImage: `url(${FWD})`,
        display: "flex",
    });

    // const testGet = async () => {
    //   const params = {
    //     mobileOs: "ios",
    //   };

    //   const api =
    //     "https://uat-www.fwd.com.my/iris_gateway/iris_share/resource/public/version/checkVersion";

    //   const result = await doGet(api, {}, params);
    //   console.log("Get result: ", result);
    //   //https://uat-www.fwd.com.my/iris_gateway/iris_share/resource/public/version/checkVersion?mobileOs=ios
    // };

    // const testJsonPost = async () => {
    //   const body = {
    //     username: "A00007",
    //     factorType: "SMS",
    //   };

    //TODO: example for ellie
    // const api = API.activateAcc.activateAccountBySMS
    //   .replace(':userId', "smsozxssx1KiBE9bh1d6")
    //   .replace(':factorId', "smsozxssx1KiBE9bh1d6");
    // const result = await doJsonPost(api, {}, body);

    //   const result = await doJsonPost(API.forgetPw.sendOTPByUsername, {}, body);
    //   console.log("JsonPost result: ", result);
    // };

    // const testFormPost = async () => {
    //   const body = {
    //     username: "B00007",
    //     password: "Pass@1234",
    //     grant_type: "password",
    //   };

    //   const result = await doFormPost(API.login, {}, body);
    //   console.log("FormPost result: ", result);
    // };

    return (
        <Div direction="col" position="relative" style={{ height: "100%" }}>
            {/* <button
        onClick={() => {
          testGet();
        }}
      >
        Felix Test Get
      </button>
      <button
        onClick={() => {
          testJsonPost();
        }}
      >
        Felix Test Json Post
      </button>
      <button
        onClick={() => {
          testFormPost();
        }}
      >
        Felix Test Form Post
      </button> */}
            <LogoWrap center>
                <a href={"/"}>
                    <img
                        src={fwd_logo}
                        style={{ width: 200 }}
                        alt="fwd_logo"></img>
                </a>
            </LogoWrap>

            <LoginBackground direction={"col"}>
                <ChangeLang />
                <Switch>
                    {/* if  url is /, return form according to the page state*/}
                    <Route exact path="/">
                        {page === "login" && (
                            <>
                                <LoginTitle lng={currentLanguage} center>
                                    {t("LOGIN_PAGE.LOGIN_TITLE")}
                                </LoginTitle>
                                <LoginForm
                                    t={t}
                                    resetPw={() => switchPage("forgetPw")}
                                />
                            </>
                        )}

                        {page === "forgetPw" && (
                            <>
                                <LoginTitle lng={currentLanguage} center>
                                    {t("LOGIN_PAGE.FORGOT_PW_TITLE")}
                                </LoginTitle>
                                <ForgetPWForm
                                    t={t}
                                    onBack={() => {
                                        switchPage("login");
                                    }}
                                    onSubmit={() => {
                                        switchPage("changePw");
                                    }}
                                />
                            </>
                        )}

                        {page === "changePw" && (
                            <>
                                <LoginTitle lng={currentLanguage} center>
                                    {t("LOGIN_PAGE.CHANGE_PW_TITLE")}
                                </LoginTitle>
                                <ChangePwForm
                                    t={t}
                                    onBack={() => {
                                        switchPage("login");
                                        dispatch(clearResetPwState());
                                    }}
                                    selectUser={() => switchPage("selectUser")}
                                />
                            </>
                        )}

                        {page === "setPw" && (
                            <>
                                <LoginTitle lng={currentLanguage} center>
                                    {t("LOGIN_PAGE.SET_PW_TITLE")}
                                </LoginTitle>
                                <SetPwForm
                                    t={t}
                                    login={() => switchPage("login")}
                                />
                            </>
                        )}

                        {/* selectUser only available  for development environment */}
                        {page === "selectUser" && (
                            <>
                                <LoginTitle lng={currentLanguage} center>
                                    {t(
                                        "Select your agent code to reset password",
                                    )}
                                </LoginTitle>
                                <SelectUserForm
                                    t={t}
                                    onBack={() => switchPage("login")}
                                    onSwitchPage={() => switchPage("changePw")}
                                />
                            </>
                        )}
                    </Route>

                    {/* if  url is /register, return verify form*/}
                    <Route path="/register">
                        <>
                            <LoginTitle lng={currentLanguage} center>
                                {t("LOGIN_PAGE.REG_TITLE")}
                            </LoginTitle>
                            <RegForm t={t} setPw={() => switchPage("setPw")} />
                        </>
                    </Route>
                    <Route>
                        <NoMatch />
                    </Route>
                </Switch>
            </LoginBackground>

            {/* <Button onClick={()=>logon(true)}>{t('Login')}</Button> */}
        </Div>
    );
};

export default LoginPage;
