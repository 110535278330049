const lang = {
    LOGIN_PAGE: {
        LOGIN_TITLE: "ចូល FWD",
        FORGOT_PW_TITLE: "រកលេខ ID ទីប្រឹក្សា ឬដាក់លេខកូដសម្ងាត់ឡើងវិញ",
        CHANGE_PW_TITLE: "ភ្លេចលេខ ID ទីប្រឹក្សា ឬលេខកូដសម្ងាត់",
        SET_PW_TITLE: "ដាក់លេខកូដសម្ងាត់ដើម្បីចូល",
        REG_TITLE: "បើកដំណើរការគណនី",
    },
    LOGIN_FORM: {
        ID: "លេខ ID ទីប្រឹក្សា",
        PASSWORD: "លេខកូដសម្ងាត់",
        FORGOT_PW: "ភ្លេចលេខ ID ទឹប្រឹក្សា/លេខសម្ងាត់ឬ?",
        LOGIN: "ចូល",
        MSG: {
            NO_ID: "សូមបញ្ចូលលេខសម្គាល់ទីប្រឹក្សា",
            NO_PW: "សូមបញ្ចូលពាក្យសម្ងាត់",
            NOT_VALID:
                "លេខសម្ងាត់ត្រូវតែមានយ៉ាងហោចណាស់ 8 តួអក្សររួមមានយ៉ាងហោចណាស់អក្សរតូចអក្សរធំលេខនិមិត្តសញ្ញា",
            INCORRECT: "លេខសម្ងាត់ / ទីប្រឹក្សាមិនត្រឹមត្រូវ!",
            LOGIN_SUCCESS: "ចូលដោយជោគជ័យ",
        },
    },
    REG_FORM: {
        ENTER_OTP: "បញ្ចូលលេខកូដផ្ទៀងផ្ទាត់",
        SENT: "យើងខ្ញុំបានផ្ញើកូដផ្ទៀងផ្ទាត់ចំនួន 6 ខ្ទង់ទៅលេខ",
        NOT_SENT: `សូមចុចប៊ូតុង "ផ្ញើលេខកូដ" ដើម្បីទទួលបាន "លេខកូដសម្ងាត់មួយកាល" និងដាក់លេខកូដសម្ងាត់ផ្ទាល់ខ្លួនរបស់លោកអ្នក`,
        MSG: {
            SIX_DIGIT: "សូមបញ្ចូល 6 ខ្ទង់",
            WRONG_CODE: "លេខកូដមិនត្រឹមត្រូវ",
        },
    },
    FORGET_PW_FORM: {
        OTP_TIP:
            "សូមបញ្ចូលលេខទូរស័ព្ទដែលបានប្រើនៅពេលចុះឈ្មោះ យើងខ្ញុំនឹងផ្ញើលេខកូដសម្ងាត់មួយកាលជូនលោកអ្នក",
        PHONE: "លេខទូរស័ព្ទ",
        SEND: "ផ្ញើលេខកូដសម្ងាត់មួយកាល",
        BACK: "ត្រឡប់ទៅទីចូល",
    },
    CHANGE_PW_FORM: {
        SENT: "យើងខ្ញុំបានផ្ញើកូដផ្ទៀងផ្ទាត់ចំនួន 6 ខ្ទង់ទៅលេខ",
        ENTER_OTP: "បញ្ចូលលេខកូដផ្ទៀងផ្ទាត់",
        BACK: "ត្រឡប់ទៅទីចូល",
        VERIFIED: "គណនីរបស់លោកអ្នកត្រូវបានផ្ទៀងផ្ទាត់រួចរាល់",
        YOUR_ID: "លេខ ID ទីប្រឹក្សា",
        NEW_PASSWORD_MSG:
            "បើលោកអ្នកភ្លេចលេខសម្ងាត់របស់ខ្លួន លោកអ្នកអាចបង្កើតលេខសម្ងាត់ថ្មីដោយអនុវត្តតាមការណែនាំខាងក្រោម",
        LEAST_CHAR: "យ៉ាងហោចណាស់ 8 តួ",
        COMBINATION:
            "ត្រូវយកតួអក្សរធំ ទៅផ្សំគ្នានឹងអក្សរតូច តួលេខ និងតួសញ្ញាផ្សេងៗ",
        NO_OLD: "សូមកុំប្រើលេខកូដសម្ងាត់ចាស់របស់លោកអ្នកឡើយ",
        NEW_PW: "លេខកូដសម្ងាត់ថ្មី",
        CONFIRM_PW: "បញ្ជាក់លេខកូដសម្ងាត់ថ្មី",
        CHANGE_PW: "ប្ដូរលេខកូដសម្ងាត់",
        RESET_SUCCESS: "លេខកូដសម្ងាត់របស់លោកអ្នកត្រូវបានបង្កើតឡើងវិញរួចរាល់!",
        MSG: {
            NO_PW: "សូមបញ្ចូលលេខកូដសម្ងាត់ថ្មីរបស់លោកអ្នក",
            NOT_VALID:
                "លេខកូដសម្ងាត់លោកអ្នកត្រូវមានតួអក្សរធំ ផ្សំគ្នានឹងតួអក្សរតូច តួសញ្ញាដទៃទៀត (ដូចជា !, $, #, % ។ល។) និងតួលេខ",
            NOT_MATCH: "សូមប្រាកដថា លេខកូដសម្ងាត់លោកអ្នក ត្រូវគ្នាពិតមែន",
            WARN: "សូមប្រាកដថា លេខសម្ងាត់ថ្មីរបស់លោកអ្នក ត្រឹមត្រូវតាមការណែនាំពិតមែន",
        },
    },
    SET_PW_FORM: {
        SET_PW_TITLE: "សូមបើកដំណើរការគណនី ដោយដាក់លេខសម្ងាត់ដំបូងរបស់លោកអ្នក",
        LEAST_CHAR: "យ៉ាងហោចណាស់ 8 តួ",
        COMBINATION:
            "ត្រូវយកតួអក្សរធំ ទៅផ្សំគ្នានឹងអក្សរតូច តួលេខ និងតួសញ្ញាផ្សេងៗ",
        NO_OLD: "សូមកុំប្រើលេខកូដសម្ងាត់ចាស់របស់លោកអ្នកឡើយ",
        NEW_PW: "លេខកូដសម្ងាត់ថ្មី",
        CONFIRM_PW: "បញ្ជាក់លេខកូដសម្ងាត់ថ្មី",
        SET_PW: "ដាក់លេខកូដសម្ងាត់",
        MSG: {
            NO_PW: "សូមបញ្ចូលលេខកូដសម្ងាត់ថ្មីរបស់លោកអ្នក",
            NOT_VALID:
                "លេខកូដសម្ងាត់លោកអ្នកត្រូវមានតួអក្សរធំ ផ្សំគ្នានឹងតួអក្សរតូច តួសញ្ញាដទៃទៀត (ដូចជា !, $, #, % ។ល។) និងតួលេខ",
            NOT_MATCH: "សូមប្រាកដថា លេខកូដសម្ងាត់លោកអ្នក ត្រូវគ្នាពិតមែន",
            WARN: "សូមប្រាកដថា លេខសម្ងាត់ថ្មីរបស់លោកអ្នក ត្រឹមត្រូវតាមការណែនាំពិតមែន",
            FAIL: "ដំណើរការមិនជោគជ័យ សូមព្យាយាមម្ដងទៀត",
            ACTIVATED: "គណនីត្រូវបានបើកឱ្យដំណើរការ កំពុងរៀបរយចូល...",
        },
    },
    SELECT_USER_FORM: {
        AGENT_CODE: "លេខកូដភ្នាក់ងារ",
        SEND: "ផ្ញើលេខកូដសម្ងាត់មួយកាល",
        BACK: "ត្រឡប់ទៅទីចូល",
    },
    VERIFY_FILED: {
        CONFIRM: "បញ្ជាក់",
        SEND_CODE: "ផ្ញើលេខកូដ",
        RESEND: {
            PREFIX: "អាចផ្ញើសារឡើងវិញបន្ទាប់ពី",
            SUFFIX: "វិនាទី",
        },
    },
    APPS: {
        IOS_DOWNLOAD: "ទាញយក IOS",
        ANDROID_DOWNLOAD: "ទាញយក Android",
    },
};

const translated = { translation: lang };
export default translated;
