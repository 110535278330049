import React from "react";
import { logout } from "../../modules/auth/slice";
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch } from "react-redux";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import Background from "../../components/container/Background";

export default function LogoutToRegister() {
    const classes = useStyles();
    const dispatch = useDispatch();

    const handleLogout = () => {
        dispatch(logout());
    };

    return (
        <Background>
            <div>Please logout to continue registration</div>
            <div
                onClick={() => {
                    handleLogout();
                }}>
                <ExitToAppIcon className={classes.exit} />
            </div>
        </Background>
    );
}

const useStyles = makeStyles(() => ({
    exit: {
        color: "#000000",
        fontSize: "50px",
        "&:hover": {
            opacity: "0.6",
            cursor: "pointer",
        },
    },
}));
