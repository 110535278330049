import registerReducer from "../modules/register/slice";
import appsCenterReducer from "../modules/appsCenter/appsCenterSlice";
import authReducer from "../modules/auth/slice";
import regReducer from "../modules/reg/slice";
import translationReducer from "../modules/translation/slice";
import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";
import {
    persistStore,
    persistReducer,
    FLUSH,
    REHYDRATE,
    PAUSE,
    PERSIST,
    PURGE,
    REGISTER,
} from "redux-persist";
import storage from "redux-persist/lib/storage";

const authPersistConfig = {
    key: "auth",
    blacklist: ["state"],
    version: 1,
    storage,
};

const langPersistConfig = {
    key: "lang",
    version: 1,
    storage,
};

// const persistedLeadReducer = persistReducer(persistConfig, leadReducer);
const persistedAuthReducer = persistReducer(authPersistConfig, authReducer);
const persistedTranslationReducer = persistReducer(
    langPersistConfig,
    translationReducer,
);

const store = configureStore({
    reducer: {
        // counter: counterReducer,
        register: registerReducer,
        // lead: leadReducer,
        // lead: persistedLeadReducer,
        appsCenter: appsCenterReducer,
        // auth: authReducer,
        auth: persistedAuthReducer,
        reg: regReducer,
        lang: persistedTranslationReducer,
    },
    middleware: getDefaultMiddleware({
        serializableCheck: {
            ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
        },
    }),
});

let persistor = persistStore(store);

export { persistor, store };
