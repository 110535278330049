export const FWD_COLOR = {
    APP_BACKGROUND: "#F5F5F5",
    BLACK: "#000000",
    DARK_GREEN: "#183028",
    GRAY_1: "#333333",
    GRAY_3: "#828282",
    GRAY_4: "#BDBDBD",
    ORANGE: "#E87722",
    ORANGE_50: "#F3BB90",
    GRAY_SEPERATOR: "#E0E0E0",
    GREEN_NOTI: "#6ECEB2",
    WHITE: "#FFFFFF",
};

export const FWD_FONT = {
    BLACK: "FWDCircularTT-Black",
    BLACK_ITALIC: "FWDCircularTT-BlackItalic",
    BOLD: "FWDCircularTT-Bold",
    BOOK: "FWDCircularTT-Book",
    BOLD_ITALIC: "FWDCircularTT-BoldItalic",
    MEDIUM: "FWDCircularTT-Medium",
    MEDIUM_ITALIC: "FWDCircularTT-MediumItalic",
};

export const FWD_KH_FONT = {
    BLACK: "NotoSansKhmer-Black",
    BOLD: "NotoSansKhmer-Bold",
    REGULAR: "NotoSansKhmer-Regular",
    MEDIUM: "NotoSansKhmer-Medium",
    MEDIUM_ITALIC: "NotoSansKhmer-MediumItalic",
};

