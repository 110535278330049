import styled from "@emotion/styled";

const ErrorMsg = styled.div`
    color: red;
    padding-top: 2px;
    box-sizing: border-box;
    font-size: 12px;
`;

export default ErrorMsg;
