import { makeStyles } from "@material-ui/core/styles";

export const formStyles = makeStyles((theme) => ({
    form: {
        backgroundColor: theme.palette.background.default,
    },
    fieldName: {
        paddingTop: "5px",
        paddingBottom: "5px",
        fontWeight: 700,
    },
    forgotPW: {
        color: theme.palette.primary.main,
        cursor: "pointer",
        fontWeight: 700,
        marginBottom: "10px",
    },
    backBtn: {
        color: theme.palette.primary.main,
        cursor: "pointer",
        fontWeight: 700,
        marginBottom: "10px",
    },
}));
