import React from "react";
import { fwd_logo, FWD } from "../../assets/images/index";
import { styled } from "@material-ui/core/styles";
import { Div } from "../../components";

export default function Background({ children }) {
    const StyledDiv = styled(Div)({
        padding: 20,
        margin: 20,
        background: "white",
        borderRadius: 8,
    });
    const LogoWrap = styled(Div)({
        paddingTop: "20px",
        paddingBottom: "20px",
        background: "white",
        width: "100%",
    });
    return (
        <div
            style={{
                width: "100vw",
                height: "100vh",
                alignItems: "center",
                backgroundImage: `url(${FWD})`,
            }}>
            <LogoWrap center>
                <a href={"/"}>
                    <img
                        src={fwd_logo}
                        style={{ width: 200 }}
                        alt="fwd_logo"></img>
                </a>
            </LogoWrap>
            <StyledDiv>{children}</StyledDiv>
        </div>
    );
}
