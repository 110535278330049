import React, { useState } from "react";
import { FormField, ErrorMsg, FwdButton, Form, Loading } from "./index";
import { useTheme } from "@material-ui/core/styles";
import { AuthSlice } from "../modules/auth";
import { useDispatch, useSelector } from "react-redux";
import { formStyles } from "./style/formStyle";
import { useHistory } from "react-router-dom";
import { pwValidation, checkIfNoInput } from "../validation";
import { selectAuthLoading } from "../modules/auth/selector";
import { clearResetPwState } from "../modules/auth/slice";

const LoginForm = ({ resetPw, t }) => {
    const classes = formStyles();
    const dispatch = useDispatch();
    const authLoading = useSelector(selectAuthLoading);
    const [idMsg, setIdMsg] = useState(null); //"Please input Advisor ID"
    const [pwMsg, setPwMsg] = useState(null); //"Please input Password"
    const [submitMsg, setSubmitMsg] = useState(null); //"please "
    const [form, setForm] = useState({
        advisorID: "",
        password: "",
    });
    const history = useHistory();

    const inputAdvisorID = (e) => {
        e.preventDefault();
        setIdMsg(null);
        setForm({ ...form, advisorID: e.currentTarget.value });
    };
    const inputPw = (e) => {
        e.preventDefault();
        setPwMsg(null);
        setForm({ ...form, password: e.currentTarget.value });
    };

    const theme = useTheme();

    const submit = async (e) => {
        e.preventDefault();
        if (checkIfNoInput(form.advisorID) || checkIfNoInput(form.password)) {
            if (checkIfNoInput(form.advisorID)) {
                setIdMsg(t("LOGIN_FORM.MSG.NO_ID"));
            }
            if (checkIfNoInput(form.password)) {
                setPwMsg(t("LOGIN_FORM.MSG.NO_PW"));
            }
            if (pwValidation(form.password)) {
                setPwMsg(t("LOGIN_FORM.MSG.NOT_VALID"));
            }
            return;
        }

        let result = await dispatch(
            AuthSlice.loginAsync({
                username: form.advisorID,
                password: form.password,
            }),
        );

        const token = result.payload.token;

        if (!token) {
            return setSubmitMsg(t("LOGIN_FORM.MSG.INCORRECT"));
        }
        setSubmitMsg(t("LOGIN_FORM.MSG.LOGIN_SUCCESS"));
        return history.push("/home/dashboard");
    };

    return (
        <>
            {authLoading === "loading" && <Loading />}

            <Form color={theme.palette.background.default}>
                <div style={{ width: "100%" }}>
                    <div className={classes.fieldName}>
                        {t("LOGIN_FORM.ID")}
                    </div>
                </div>
                <FormField
                    style={{ width: "100%", height: "15%" }}
                    type="text"
                    value={form.advisorID}
                    onChange={inputAdvisorID}></FormField>

                <ErrorMsg>{idMsg}</ErrorMsg>
                <div style={{ width: "100%" }}>
                    <div className={classes.fieldName}>
                        {t("LOGIN_FORM.PASSWORD")}
                    </div>
                </div>
                <FormField
                    style={{ width: "100%", height: "15%" }}
                    type="password"
                    value={form.password}
                    onChange={inputPw}></FormField>

                <ErrorMsg>{pwMsg}</ErrorMsg>
                <div
                    onClick={() => {
                        resetPw();
                        dispatch(clearResetPwState());
                    }}
                    className={classes.forgotPW}>
                    {t("LOGIN_FORM.FORGOT_PW")}
                </div>
                <FwdButton style={{ width: "100%" }} onClick={submit}>
                    {t("LOGIN_FORM.LOGIN")}
                </FwdButton>
                <ErrorMsg>{submitMsg}</ErrorMsg>
            </Form>
        </>
    );
};

export default LoginForm;
