import React, { useState, useEffect } from "react";
import { Form, Loading, VerifyField } from "./index";
import { formStyles } from "./style/formStyle";
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch, useSelector } from "react-redux";
import { RegSelector, RegSlice } from "../modules/reg";
import { getUserId } from "../modules/reg/slice";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useHistory } from "react-router-dom";
import { doJsonPost } from "../utils/Network";
import API from "../utils/API";
import * as dotenv from "dotenv";
dotenv.config();

const RegForm = ({ setPw, t }) => {
    //style
    const below450px = useMediaQuery("(max-width:450px)");
    const useStyles = makeStyles(() => ({
        padding: {
            paddingTop: "15px",
            paddingBottom: "10px",
        },
        box: {
            width: "15%",
            height: `100%`,
            textAlign: "center",
        },
        boxWrap: {
            display: "flex",
            justifyContent: "space-between",
            height: below450px ? "22%" : "40%",
            maxHeight: "70px",
        },
        resend: {
            paddingTop: "5%",
        },
    }));
    const classes = formStyles();
    const fieldStyle = useStyles();

    //redux
    const dispatch = useDispatch();
    const factorId = useSelector(RegSelector.selectFactorId);
    const factorResult = useSelector(RegSelector.selectFactorResult);
    const phoneNumber = useSelector(RegSelector.selectPhoneNumber);
    const smsState = useSelector(RegSelector.selectSMSState);
    const history = useHistory();
    const error = useSelector(RegSelector.selectError);
    const urlParams = new URLSearchParams(window.location.search);
    const userId = urlParams.get("id") || urlParams.get("Id");

    useEffect(() => {
        if (error === "Api validation failed: factorEnrollRequest") {
            dispatch(RegSlice.retrieveFactorIdAsync(userId));
            setSmsChallenge(true);
        }
    }, [error, dispatch, userId]);

    //state
    // const [phoneNum, setPhoneNum] = useState(null);
    const [isSentOTP, setIsSentOTP] = useState(false);
    const [errorMsg, setErrorMsg] = useState("");
    const [submitState, setSubmitState] = useState(false);
    const [smsChallenge, setSmsChallenge] = useState(false);

    //focus on the ref according to array index

    //store userId to redux
    useEffect(() => {
        dispatch(getUserId(userId));
        // eslint-disable-next-line
    }, [userId]);

    //
    useEffect(() => {
        if (factorResult === "SUCCESS") {
            history.push("/");
            return setPw();
        }
    }, [factorResult, history, setPw]);

    //check if input key is a number

    const submitCode = async (code) => {
        //only number is allow to be inputted, no need type check

        if (smsChallenge) {
            dispatch(RegSlice.smsChallengeVerifyAsync(code));
            return;
        }

        if (code.length !== 6) {
            return setErrorMsg("REG_FORM.MSG.SIX_DIGIT");
        }

        const url = API.activateAcc.activateAccountBySMS
            .replace(":userId", userId)
            .replace(":factorId", factorId);
        const result = await doJsonPost(
            url,
            {},
            {
                passCode: code,
            },
        );

        setSubmitState(true);
        if (result.status !== "ACTIVE") {
            setSubmitState(false);
            return setErrorMsg("REG_FORM.MSG.WRONG_CODE");
        }
        //set all state to initial state
        setSubmitState(false);
        setErrorMsg("");

        //set Loginpage page to setPw then push to ('/')
        history.push("/");
        return setPw();
    };

    // //Submit OTPs

    return (
        <>
            {(smsState === "loading" || submitState) && <Loading />}

            <Form style={{ height: "auto" }} className={classes.form}>
                <div>
                    {isSentOTP ? t("REG_FORM.SENT") : t("REG_FORM.NOT_SENT")}
                    <span className={classes.fieldName}>{phoneNumber}</span>
                </div>

                <div className={fieldStyle.padding}>
                    {t("REG_FORM.ENTER_OTP")}:
                </div>

                <VerifyField
                    onResend={() => {
                        dispatch(RegSlice.getFactorId(userId));
                        setIsSentOTP(true);
                        setErrorMsg("");
                    }}
                    initialTimer={false}
                    error={errorMsg + error}
                    onConfirm={submitCode}
                    t={t}
                />
            </Form>
        </>
    );
};

export default RegForm;
