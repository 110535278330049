import styled from "@emotion/styled";
import { FWD_COLOR } from "../config";

const FormField = styled.input`
    box-sizing: border-box;
    max-height: 47px;
    min-height: 47px;
    padding: 10px 5px;
    border: solid 1px #e5e5e5;
    background-color: #f5f5f5;
    border-radius: 5px;
    outline: none;
    &:focus {
        outline: none;
        border: solid 1px ${FWD_COLOR.ORANGE};
    }
`;

export default FormField;
