const IRIS_HOST = window.REACT_APP_API_URL;

const API = {
    // account activation
    activateAcc: {
        registerAccount: `${IRIS_HOST}/okta/api/v1/users?activate=false`,
        //step 3
        getPhoneNumberByOktaId: `${IRIS_HOST}/systemcommon/EnrollFactor.run`,
        //step 3.2
        sendOTPByPhoneNo: `${IRIS_HOST}/okta/api/v1/users/:userId/factors`,
        //step 4-6
        activateAccountBySMS: `${IRIS_HOST}/okta/api/v1/users/:userId/factors/:factorId/lifecycle/activate`,
        set1stTimePassword: `${IRIS_HOST}/okta/api/v1/users/:userId`,
        completeAccountActivation: `${IRIS_HOST}/okta/api/v1/users/:userId/lifecycle/activate?sendEmail=false`,

        //sms challenge
        retrieveFactorId: `${IRIS_HOST}/okta/api/v1/users/:userId/factors`,
        smsChallenge: `${IRIS_HOST}/okta/api/v1/users/:userId/factors/:factorId/verify`,
    },

    //forget password
    forgetPw: {
        getUsernameByMobileNo: `${IRIS_HOST}/systemcommon/GetAgentCode.run`,
        sendOTPByUsername: `${IRIS_HOST}/okta/api/v1/authn/recovery/password`,
        verifyOTP: `${IRIS_HOST}/okta/api/v1/authn/recovery/factors/sms/verify`,
        resetPassword: `${IRIS_HOST}/okta/api/v1/authn/credentials/reset_password`,
    },

    //login
    login: `${IRIS_HOST}/oauth/token`,

    //apps Link
    appLink: {
        iris: {
            ios: `${IRIS_HOST}/iris_share/resource/public/version/checkVersion?mobileOs=reactnative-ios`,
            android: `${IRIS_HOST}/iris_share/resource/public/version/checkVersion?mobileOs=reactnative-android`,
        },
    },
    apps: `${IRIS_HOST}/iris_resource/download/apps.json`,
};

export default API;
