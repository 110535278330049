import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import styles from "./NavBar.module.css";
import fwd_nav_logo from "../../assets/images/fwd_nav_logo.png";
import Avatar from "@material-ui/core/Avatar";
import { makeStyles } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";
import Backdrop from "@material-ui/core/Backdrop";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import { useHistory } from "react-router-dom";
import { logout } from "../../modules/auth/slice";
import { selectUsername } from "../../modules/auth/selector";

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        "& > *": {
            margin: theme.spacing(1),
        },
    },
    small: {
        width: theme.spacing(3),
        height: theme.spacing(3),
    },
    large: {
        width: theme.spacing(25),
        height: theme.spacing(25),
        fontSize: "50px",
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: "#fff",
    },
    exit: {
        color: "#000000",
        fontSize: "50px",
        "&:hover": {
            opacity: "0.6",
            cursor: "pointer",
        },
    },
}));

export default function NavBar() {
    const classes = useStyles();
    const history = useHistory();
    const dispatch = useDispatch();
    const [isSideBarActive, setIsSideBarActive] = useState(false);
    const [open, setOpen] = React.useState(false);
    const username = useSelector(selectUsername);

    const handleClose = () => {
        setOpen(false);
        setIsSideBarActive(false);
    };

    const handleToggle = () => {
        setOpen(!open);
        setIsSideBarActive(!isSideBarActive);
    };

    const handleLogout = () => {
        dispatch(logout());
        history.push("/");
    };

    return (
        <div className={styles.navBar}>
            <div className={styles.navBarContainer}>
                <a href={"/#"}>
                    <img src={fwd_nav_logo} alt="fwd_logo" />
                </a>
            </div>
            <div
                className={styles.userProfile}
                onClick={() => {
                    handleToggle();
                }}>
                <div className={styles.profilePic}>
                    <Avatar alt="Remy Sharp" src="/static/images/avatar/1.jpg">
                        {username[0]}
                    </Avatar>
                </div>
                <div className={styles.userName}>{username}</div>
            </div>

            <Backdrop
                className={classes.backdrop}
                open={open}
                onClick={handleClose}>
                <div
                    className={styles.sideBar}
                    style={
                        isSideBarActive
                            ? { right: "0", zIndex: "1" }
                            : { right: "-400px", zIndex: "-1" }
                    }>
                    <div
                        className={styles.closeIcon}
                        onClick={() => {
                            handleClose();
                        }}>
                        <CloseIcon fontSize={"large"} />
                    </div>
                    <div className={styles.profileSection}>
                        <div className={classes.root}>
                            <Avatar
                                className={classes.large}
                                alt="Remy Sharp"
                                src="/static/images/avatar/1.jpg">
                                {username[0]}
                            </Avatar>
                        </div>
                        <div>{username}</div>
                    </div>
                    <div
                        onClick={() => {
                            handleLogout();
                        }}>
                        <ExitToAppIcon className={classes.exit} />
                    </div>
                </div>
            </Backdrop>
        </div>
    );
}
