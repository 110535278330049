import { doGet } from "../../utils/Network";
import API from "../../utils/API";
const IRIS_HOST = window.REACT_APP_API_URL;

export async function fetchApps() {
    const appsRes = await doGet(API.apps, {}, {});

    const { apps } = appsRes;
    
    apps.map((app) => {
        const androidLLinkSuffix = app.androidLink.slice();
        const iosLLinkSuffix = app.iosLink.slice();

        app.androidLink = `${IRIS_HOST}${androidLLinkSuffix}`;
        app.iosLink = `${IRIS_HOST}${iosLLinkSuffix}`;

        return app;
    });

    return appsRes;
}