import { doJsonPost, doGet } from "../../utils/Network";
import API from "../../utils/API";

export const sendSMS = async (userId) => {
    const phoneNumUrl = API.activateAcc.getPhoneNumberByOktaId;
    const url = API.activateAcc.sendOTPByPhoneNo.replace(":userId", userId);

    const phoneNumResult = await doJsonPost(
        phoneNumUrl,
        {},
        {
            oktaUserId: userId,
        },
    );

    if (
        !phoneNumResult.response?.phoneNumber ||
        phoneNumResult.status?.statusmessage !== "success"
    ) {
        return { errorSummary: phoneNumResult.status?.statusmessage };
    }
    const { phoneNumber } = phoneNumResult.response;
    const countryCode = process.env.REACT_APP_COUNTRY_CODE;
    let phonePrefix;

    if (phoneNumber.slice(0, countryCode.length + 1) === `+${countryCode}`) {
        //if phoneNumber comes with Country Code
        phonePrefix = ``;
    } else if (phoneNumber.slice(0, countryCode.length) === `${countryCode}`) {
        //if phoneNumber comes with Country Code without + sign
        phonePrefix = "+";
    } else if (
        phoneNumber.slice(0, countryCode.length + 1) !== `+${countryCode}` &&
        phoneNumber.slice(0, countryCode.length) !== `${countryCode}`
    ) {
        //Assume local phone number only
        phonePrefix = `+${countryCode}`;
    }

    const result = await doJsonPost(
        url,
        {},
        {
            factorType: "sms",
            provider: "OKTA",
            profile: {
                phoneNumber: phonePrefix + phoneNumber,
            },
        },
    );

    return result;
};

export const retrieveFactorId = async (userId) => {
    const url = API.activateAcc.retrieveFactorId.replace(":userId", userId);

    const result = await doGet(url, {}, {});

    return result;
};

export const smsChallenge = async (userId, factorId) => {
    const url = API.activateAcc.smsChallenge
        .replace(":userId", userId)
        .replace(":factorId", factorId);

    const result = await doJsonPost(url, {}, {});

    return result;
};

export const smsChallengeVerify = async (userId, factorId, passCode) => {
    const url = API.activateAcc.smsChallenge
        .replace(":userId", userId)
        .replace(":factorId", factorId);
    const body = {
        passCode,
    };
    const result = await doJsonPost(url, {}, body);

    return result;
};
