import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { ErrorMsg, FwdButton, Form, Loading } from "./index";
import { formStyles } from "./style/formStyle";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch, useSelector } from "react-redux";
import { RegSelector } from "../modules/reg";
import { pwValidation } from "../validation";
import { doJsonPost } from "../utils/Network";
import Input from "@material-ui/core/Input";
import InputAdornment from "@material-ui/core/InputAdornment";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import IconButton from "@material-ui/core/IconButton";
import API from "../utils/API";
import * as dotenv from "dotenv";
import { getUserId, getFactorId } from "../modules/reg/slice";
dotenv.config();

const SetPwForm = ({ login, t }) => {
    //style
    const useStyles = makeStyles({
        form: {
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
        },
    });

    const pwFormStyle = useStyles();
    const togglePwVisibility = () => {
        setNewPw({
            ...newPw,
            showPassword: !newPw.showPassword,
        });
    };

    const formStyle = {
        height: "auto",
        flexDirection: "row",
        maxHeight: "400px",
        minWidth: "600px",
    };

    const mobileFormStyle = {
        height: "auto",
        flexDirection: "column",
        maxHeight: "none",
        maxWidth: "90vw",
        overflow: "auto",
        paddingTop: "20px",
        marginBottom: "20px",
    };

    const mobileDivStyle = {
        width: "100%",
    };

    const lapTopStyle = { width: "60%", height: "100%" };

    const fieldStyle = {
        width: "100%",
    };

    const bulletPoints = {
        color: "#878787",
    };

    const classes = formStyles();
    const above600px = useMediaQuery("(min-width:600px)");

    //state

    const initialState = {
        pw: "",
        confirmPw: "",
        showPassword: false,
    };
    const [newPw, setNewPw] = useState(initialState);
    const [errorMsg, setErrorMsg] = useState("");
    const [isPwValid, setIsPwValid] = useState(false);
    const [loading, setLoading] = useState(false);
    const history = useHistory();

    const dispatch = useDispatch();

    //handle input
    const inputPw = (e) => {
        setNewPw({
            ...newPw,
            pw: e.currentTarget.value,
        });
    };

    const inputConfirmPw = (e) => {
        setNewPw({
            ...newPw,
            confirmPw: e.currentTarget.value,
        });
    };

    //validation
    const confirmInputPwMatch = () => {
        const pw = newPw.pw;
        const confirmPw = newPw.confirmPw;
        if (pw !== confirmPw) {
            setErrorMsg(`${t("SET_PW_FORM.MSG.NOT_MATCH")}`);
            return setIsPwValid(false);
        }
        if (pwValidation(pw) && pw === confirmPw) {
            setErrorMsg("");
            return setIsPwValid(true);
        }
    };

    const validateInputPw = () => {
        const pw = newPw.pw;
        const confirmPw = newPw.confirmPw;
        if (!pw) {
            setErrorMsg(`${t("SET_PW_FORM.MSG.NO_PW")}`);
            return setIsPwValid(false);
        }

        if (!pwValidation(pw)) {
            setErrorMsg(`${t("SET_PW_FORM.MSG.NOT_VALID")}`);
            return setIsPwValid(false);
        }

        if (confirmPw === "") {
            setErrorMsg("");
            return setIsPwValid(false);
        }

        if (pwValidation(pw) && pw === confirmPw) {
            return setIsPwValid(true);
        }
    };

    //"errorSummary": "password: Password requirements were not met.
    //Password requirements: at least 8 characters, a lowercase letter, an uppercase letter, a number, a symbol, no parts of your username, does not include your first name, does not include your last name. Your password cannot be any of your last 5 passwords. At least 2 hour(s) must have elapsed since you last changed your password."

    //get userId for posting
    const userId = useSelector(RegSelector.selectUserId);

    //show successful and redirect to login
    const showLoading = () => {
        return new Promise((resolve) =>
            setTimeout(() => {
                history.push("/");
                resolve();
            }, 2000),
        );
    };

    //submit form after checking
    const submitForm = async (e) => {
        e.preventDefault();
        if (!isPwValid || newPw.pw !== newPw.confirmPw) {
            return setErrorMsg(`${t("SET_PW_FORM.MSG.WARN")}`);
        }

        const url = API.activateAcc.set1stTimePassword.replace(
            ":userId",
            userId,
        );
        const result = await doJsonPost(
            url,
            {},
            {
                credentials: {
                    password: {
                        value: newPw.confirmPw,
                    },
                },
            },
        );

        if (result?.errorCauses) {
            return setErrorMsg(result.errorCauses.errorSummary);
        } else if (result?.status) {
            // setErrorMsg("");
            setNewPw(initialState);
            let url = API.activateAcc.completeAccountActivation.replace(
                ":userId",
                userId,
            );
            const activateResult = await doJsonPost(url);
            if (!activateResult.activationToken) {
                return setErrorMsg(`${t("SET_PW_FORM.MSG.FAIL")}`);
            }
            // setErrorMsg("Successful, wait till we redirect you to login page");
            setLoading(true);
            // await showLoading();
            await showLoading();
            login();

            dispatch(getUserId(null));
            dispatch(getFactorId(null));
            return setErrorMsg("");
        }
    };

    return (
        <>
            {loading && <Loading msg={`${t("SET_PW_FORM.MSG.ACTIVATED")}`} />}

            <Form
                style={above600px ? formStyle : mobileFormStyle}
                className={classes.form}>
                <div
                    style={
                        above600px
                            ? { width: "40%", height: "100%" }
                            : mobileDivStyle
                    }>
                    <div>{t("SET_PW_FORM.SET_PW_TITLE")}.</div>
                    <div style={bulletPoints}>
                        • {t("SET_PW_FORM.LEAST_CHAR")}
                        <br></br>• {t("SET_PW_FORM.COMBINATION")}
                        <br></br>•{t("SET_PW_FORM.NO_OLD")}
                    </div>
                </div>

                <div
                    className={pwFormStyle.form}
                    style={above600px ? lapTopStyle : mobileDivStyle}>
                    <div
                        className={classes.fieldName}
                        style={{ fontSize: "16px" }}>
                        {t("SET_PW_FORM.NEW_PW")}
                        <Input
                            style={fieldStyle}
                            type={newPw.showPassword ? "text" : "password"}
                            value={newPw.value}
                            onChange={inputPw}
                            onBlur={validateInputPw}
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={togglePwVisibility}>
                                        {newPw.showPassword ? (
                                            <Visibility />
                                        ) : (
                                            <VisibilityOff />
                                        )}
                                    </IconButton>
                                </InputAdornment>
                            }></Input>
                    </div>
                    <div
                        className={classes.fieldName}
                        style={{ fontSize: "16px" }}>
                        {t("SET_PW_FORM.CONFIRM_PW")}
                        <Input
                            style={fieldStyle}
                            type={newPw.showPassword ? "text" : "password"}
                            onChange={inputConfirmPw}
                            onBlur={confirmInputPwMatch}
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={togglePwVisibility}>
                                        {newPw.showPassword ? (
                                            <Visibility />
                                        ) : (
                                            <VisibilityOff />
                                        )}
                                    </IconButton>
                                </InputAdornment>
                            }></Input>
                    </div>
                    <ErrorMsg style={{ minHeight: "24px" }}>
                        {errorMsg}
                    </ErrorMsg>
                    <FwdButton onClick={submitForm} style={{ width: "100%" }}>
                        {t("SET_PW_FORM.SET_PW")}
                    </FwdButton>
                </div>
            </Form>
        </>
    );
};

export default SetPwForm;
