import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    selectedLanguage: "",
};

export const langSlice = createSlice({
    name: "lang",
    initialState,
    reducers: {
        selectLanguage: (state, action) => {
            state.selectedLanguage = action.payload;
        },
    },
});

export const { selectLanguage } = langSlice.actions;

export default langSlice.reducer;
