import styled from "@emotion/styled";

// const Form = styled.form`props
//   background-color: white;
//   padding: 25px;
//   border-radius: 8px;
//   min-width: 300px;
// `;
const Form = styled.form((props) => ({
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around",
    alignItems: "center",
    backgroundColor: props.color,
    padding: `clamp(10px, 7%, 25px)`,
    borderRadius: "8px",
    maxWidth: "350px",
    maxHeight: "350px",
    width: "70%",
    height: "50%",
    minWidth: "224px",
    minHeight: "260px",
}));
export default Form;
