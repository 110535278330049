const isNumber = (key) => /^[0-9]$/i.test(key);

const pwValidation = (password) => {
    //Password requirements: at least 8 characters, a  lowercase letter, an uppercase letter, a number, a symbol, no parts of your username, does not include your first name, does not include your last name. Your password cannot be any of your last 5 passwords. At least 2 hour(s) must have elapsed since you last changed your password."
    const format = /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/;

    if (!format.test(password)) {
        return false;
    }
    return true;
};

const checkIfNoInput = (content) => content.trim().length === 0;

export { isNumber, pwValidation, checkIfNoInput };
