import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import { useSpring, animated } from "react-spring/web.cjs"; // web.cjs is required for IE 11 support
import { Div, FwdButton } from "../../../components";
import { styled } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";
import AppleIcon from "@material-ui/icons/Apple";
import AndroidIcon from "@material-ui/icons/Android";
import {
    BrowserView,
    MobileView,
    isIOS,
    isAndroid,
    isIPad13,
} from "react-device-detect";

export default function AppsModal({
    img,
    id,
    name,
    content,
    androidLink,
    iosLink,
    t,
}) {
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);
    // const token = useSelector(AuthSelector.selectToken)
    // const username = useSelector(AuthSelector.selectUsername)

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <div>
            <Apps type="button" onClick={handleOpen}>
                <img src={img} alt={`app_logo_${id}`} width="100%" />
            </Apps>
            <Modal
                aria-labelledby="spring-modal-title"
                aria-describedby="spring-modal-description"
                className={classes.modal}
                open={open}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}>
                <Fade in={open}>
                    <div className={classes.paper}>
                        <CloseIcon
                            fontSize={"large"}
                            onClick={() => {
                                setOpen(false);
                            }}
                            style={{
                                cursor: "pointer",
                                position: "absolute",
                                top: "10px",
                                right: "10px",
                            }}
                        />
                        <h2 id="spring-modal-title">{name}</h2>
                        <div id="spring-modal-description">
                            {content}
                            <MobileView>
                                {isIOS && (
                                    <div className={classes.button}>
                                        <a
                                            className={classes.link}
                                            href={`itms-services://?action=download-manifest&url=${iosLink}`}>
                                            <FwdButton
                                                startIcon={
                                                    <AppleIcon
                                                        className={
                                                            classes.appLogo
                                                        }
                                                    />
                                                }>
                                                {t("APPS.IOS_DOWNLOAD")}
                                            </FwdButton>
                                        </a>
                                    </div>
                                )}
                                {isAndroid && (
                                    <div className={classes.button}>
                                        <a
                                            className={classes.link}
                                            href={androidLink}>
                                            <FwdButton
                                                startIcon={
                                                    <AndroidIcon
                                                        className={
                                                            classes.appLogo
                                                        }
                                                    />
                                                }>
                                                {t("APPS.ANDROID_DOWNLOAD")}
                                            </FwdButton>
                                        </a>
                                    </div>
                                )}
                            </MobileView>
                            {!isIPad13 && (
                                <BrowserView>
                                    <div className={classes.button}>
                                        <a
                                            className={classes.link}
                                            href={`itms-services://?action=download-manifest&url=${iosLink}`}>
                                            <FwdButton
                                                size={"large"}
                                                startIcon={
                                                    <AppleIcon
                                                        fontSize={"large"}
                                                        className={
                                                            classes.appLogo
                                                        }
                                                    />
                                                }>
                                                {t("APPS.IOS_DOWNLOAD")}
                                            </FwdButton>
                                        </a>
                                    </div>
                                    <div className={classes.button}>
                                        <a
                                            className={classes.link}
                                            href={androidLink}>
                                            <FwdButton
                                                size={"large"}
                                                startIcon={
                                                    <AndroidIcon
                                                        fontSize={"large"}
                                                        className={
                                                            classes.appLogo
                                                        }
                                                    />
                                                }>
                                                {t("APPS.ANDROID_DOWNLOAD")}
                                            </FwdButton>
                                        </a>
                                    </div>
                                </BrowserView>
                            )}
                        </div>
                    </div>
                </Fade>
            </Modal>
        </div>
    );
}

const Apps = styled(Div)({
    boxSizing: "border-box",
    display: "flex",
    justifyContent: "center",
    height: "18vw",
    width: "18vw",
    boxShadow: "3px 2px 5px rgb(0 0 0 / 30%)",
    borderRadius: "4vw",
    backgroundColor: "white",
    overflow: "hidden",
    transition: "0.2s",
    "&:hover": {
        opacity: "0.8",
        height: "20vw",
        width: "20vw",
        cursor: "pointer",
    },
});

const useStyles = makeStyles((theme) => ({
    modal: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        minHeight: "70vh",
        minWidth: "60vw",
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        border: "2px solid #000",
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        position: "relative",
        minHeight: "70vh",
        minWidth: "60vw",
    },
    button: {
        marginTop: "10px",
        width: "200px",
        textDecoration: "none",
    },
    link: {
        textDecoration: "none",
    },
}));

const Fade = React.forwardRef(function Fade(props, ref) {
    const { in: open, children, onEnter, onExited, ...other } = props;
    const style = useSpring({
        from: { opacity: 0 },
        to: { opacity: open ? 1 : 0 },
        onStart: () => {
            if (open && onEnter) {
                onEnter();
            }
        },
        onRest: () => {
            if (!open && onExited) {
                onExited();
            }
        },
    });

    return (
        <animated.div ref={ref} style={style} {...other}>
            {children}
        </animated.div>
    );
});

Fade.propTypes = {
    children: PropTypes.element,
    in: PropTypes.bool.isRequired,
    onEnter: PropTypes.func,
    onExited: PropTypes.func,
};
