import { red } from "@material-ui/core/colors";
import { createMuiTheme } from "@material-ui/core/styles";
import { FWD_COLOR } from "./config";

// A custom theme for this app
const theme = createMuiTheme({
    palette: {
        primary: {
            main: FWD_COLOR.ORANGE,
        },
        secondary: {
            main: FWD_COLOR.GRAY_1,
        },
        error: {
            main: red.A400,
        },
        background: {
            default: FWD_COLOR.WHITE,
        },
    },
});

export default theme;
