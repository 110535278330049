import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
    Login,
    RequestUsernames,
    sendSMS,
    verifySMSFactor,
    resetPw,
} from "./api";

const initialState = {
    state: "not submitted",
    token: null,
    username: "",
    resetPwInfo: {
        stateToken: "",
        username: "",
        passCode: "",
        phoneNumber: "",
    },
    resetPwStatus: {
        verifySMSSuccess: false,
        resetPwSuccess: false,
        expiresAt: "",
        resetPwError: "",
    },
};

export const loginAsync = createAsyncThunk("auth/login", async (userInfo) => {
    const tokenRes = await Login(userInfo.username, userInfo.password);
    // The value we return becomes the `fulfilled` action payload

    return { token: tokenRes, username: userInfo.username };
});

export const sendSMSAsync = createAsyncThunk(
    "auth/sendSMS",
    async ({ phoneNumber, agentCode = "" }) => {
        let phone = `${phoneNumber}`;
        let userNameRes = await RequestUsernames(phoneNumber);
        if (userNameRes?.response?.agentsList?.length == 0) {
            if (phoneNumber.slice(0, 3) == "855") {
                userNameRes = await RequestUsernames(
                    "0" + phoneNumber.slice(3),
                );
            }
        }

        let usernames = "";
        let username = agentCode;
        let stateToken = "";

        const {
            response,
            status: { statusmessage },
        } = userNameRes;
        usernames = response.agentsList;

        if (statusmessage !== "success") {
            return { errorSummary: statusmessage };
        }

        switch (usernames.length) {
            case 0:
                return { errorSummary: "No record" };
            case 1:
                username = usernames[0].agentCode;
                break;
            default:
                //This error will trigger the user to select the agentCode in uat environment
                if (!agentCode)
                    return {
                        errorSummary: "More than one record",
                        usernames,
                    };
        }

        const smsRes = await sendSMS(username);
        stateToken = smsRes.stateToken;

        if (smsRes?.errorSummary) {
            return { errorSummary: smsRes.errorSummary };
        }
        return { stateToken, usernames, username, phoneNumber: phone };
    },
);

export const verifySMSFactorAsync = createAsyncThunk(
    "auth/verifySMSFactor",
    async ({ stateToken, passCode }) => {
        const verifySMSRes = await verifySMSFactor({ stateToken, passCode });

        if (verifySMSRes?.errorSummary) {
            return {
                verifySMSSuccess: false,
                resetPwSuccess: false,
                expiresAt: "",
                resetPwError: verifySMSRes.errorSummary,
            };
        }

        return {
            verifySMSSuccess: true,
            resetPwSuccess: false,
            expiresAt: verifySMSRes.expiresAt,
            resetPwError: "",
        };
    },
);

export const resetPwAsync = createAsyncThunk(
    "auth/resetPassword",
    async ({ stateToken, newPassword }) => {
        const resetPwRes = await resetPw({ stateToken, newPassword });

        if (resetPwRes?.errorSummary) {
            return {
                verifySMSSuccess: true,
                resetPwSuccess: false,
                expiresAt: resetPwRes.expiresAt,
                resetPwError: resetPwRes.errorSummary,
            };
        }

        return {
            verifySMSSuccess: true,
            resetPwSuccess: true,
            expiresAt: resetPwRes.expiresAt,
            resetPwError: "",
        };
    },
);

export const authSlice = createSlice({
    name: "auth",
    initialState,
    reducers: {
        logout: (state) => {
            state.token = undefined;
            state.username = null;
        },
        clearResetPwState: (state) => {
            state.resetPwInfo = {
                stateToken: "",
                username: "",
                passCode: "",
                phoneNumber: "",
            };
            state.resetPwStatus = {
                verifySMSSuccess: false,
                resetPwSuccess: false,
                expiresAt: "",
                resetPwError: "",
            };
        },
        clearResetPwError: (state) => {
            state.resetPwStatus.resetPwError = "";
        },
        setResetPwPhoneNumber: (state, action) => {
            state.resetPwInfo.phoneNumber = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(loginAsync.pending, (state) => {
                state.state = "loading";
            })
            .addCase(loginAsync.fulfilled, (state, action) => {
                state.state = "idle";
                const token = action.payload.token;
                if (token) {
                    state.token = token;
                    state.username = action.payload.username;
                }
                return state;
            })
            .addCase(sendSMSAsync.pending, (state) => {
                state.state = "loading";
            })
            .addCase(sendSMSAsync.fulfilled, (state, action) => {
                state.state = "idle";
                const resetPwInfo = action.payload;
                if (resetPwInfo?.errorSummary && resetPwInfo?.usernames) {
                    state.resetPwInfo.usernames = resetPwInfo.usernames;
                    state.resetPwStatus.resetPwError = resetPwInfo.errorSummary;
                    return state;
                } else if (resetPwInfo?.errorSummary) {
                    state.resetPwStatus.resetPwError = resetPwInfo.errorSummary;
                    return state;
                } else {
                    state.resetPwInfo = resetPwInfo;
                    return state;
                }
            })
            .addCase(verifySMSFactorAsync.pending, (state) => {
                state.state = "loading";
            })
            .addCase(verifySMSFactorAsync.fulfilled, (state, action) => {
                state.state = "idle";
                const resetPwStatus = action.payload;
                if (resetPwStatus?.errorSummary) {
                    state.resetPwInfo.resetPwError = resetPwStatus.errorSummary;
                    return state;
                } else {
                    state.resetPwStatus = resetPwStatus;
                    return state;
                }
            })
            .addCase(resetPwAsync.pending, (state) => {
                state.state = "loading";
            })
            .addCase(resetPwAsync.fulfilled, (state, action) => {
                state.state = "idle";
                const resetPwStatus = action.payload;
                if (resetPwStatus?.errorSummary) {
                    state.resetPwInfo.resetPwError = resetPwStatus.errorSummary;
                    return state;
                } else {
                    state.resetPwStatus = resetPwStatus;
                    return state;
                }
            });
    },
});

export const {
    logout,
    clearResetPwState,
    clearResetPwError,
    setResetPwPhoneNumber,
} = authSlice.actions;

export default authSlice.reducer;
