import { Div } from "./index";
import styled from "styled-components";
import { FWD_FONT, FWD_KH_FONT } from "../config";

const LoginTitle = styled(Div)`
    color: white;
    margin-top: clamp(22px, 3vw, 40px);
    margin-bottom: clamp(32px, 4vw, 40px);
    font-size: 32px;
    font-weight: 700;
    font-family: ${FWD_FONT.BOLD};
    font-family: ${(props) =>
        props.lng == "kh" ? FWD_KH_FONT.BOLD : FWD_FONT.BOLD};
    @media (max-width: 600px) {
        font-size: 25px;
    }
`;

export default LoginTitle;
