import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import { en_kh, kh } from "./translations";

const resources = {
    en_kh,
    kh,
};

i18n.use(initReactI18next) // passes i18n down to react-i18next
    .init({
        lng: "kh",
        fallbackLng: "en_kh",
        debug: true,
        resources,
        react: {
            wait: false,
            bindI18n: "languageChanged loaded",
            bindStore: "added removed",
            nsMode: "default",
        },
    });

export default i18n;
