import React, { useState, useRef, useEffect } from "react";
import { FormField, ErrorMsg, FwdButton, Timer } from "./index";
import { formStyles } from "./style/formStyle";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
    padding: {
        paddingTop: "15px",
        paddingBottom: "10px",
    },
    box: {
        width: "15%",
        height: `100%`,
        maxHeight: "60px",
        minHeight: "40px",
        textAlign: "center",
    },
    boxWrap: {
        display: "flex",
        justifyContent: "space-between",
        paddingBottom: "5%",
    },
    resend: {
        paddingTop: "5%",
    },
}));

export default function VerifyField({
    onResend,
    onConfirm,
    error,
    t,
    //set this to false if you want to deactivate the initial timer
    initialTimer = true,
}) {
    const initialState = ["", "", "", "", "", ""];
    const [verifyCode, setVerifyCode] = useState(initialState);
    const verifyCodeStr = verifyCode.join("");
    const inputRef1 = useRef(null);
    const inputRef2 = useRef(null);
    const inputRef3 = useRef(null);
    const inputRef4 = useRef(null);
    const inputRef5 = useRef(null);
    const inputRef6 = useRef(null);
    const classes = formStyles();
    const fieldStyle = useStyles();

    const ref = useState([
        inputRef1,
        inputRef2,
        inputRef3,
        inputRef4,
        inputRef5,
        inputRef6,
    ])[0];
    const [showResend, setShowResend] = useState(!initialTimer);
    const [focus, setFocus] = useState(0);

    const isNumber = (key) => /^[0-9]$/i.test(key);

    const handleResend = () => {
        onResend();
    };

    const handleOnPaste = (e) => {
        e.preventDefault();
        const clipboardText = e.clipboardData.getData("text/plain").trim();
        const clipboardArr = clipboardText.split("");
        if (clipboardArr.length === 6 && !isNaN(clipboardText)) {
            setVerifyCode(clipboardArr);
        }
    };

    const handleChange = (e) => {
        e.preventDefault();
        const input = e.target.value;

        if (input.length === 6) {
            const inputArr = input.split("");
            setVerifyCode(inputArr);
        }
    };

    const handleConfirm = () => {
        onConfirm(verifyCodeStr);
    };

    const field = verifyCode.map((code, i) => (
        <FormField
            key={i}
            className={fieldStyle.box}
            ref={ref[i]}
            value={code}
            onChange={handleChange}
            type={"tel"}
            onPaste={handleOnPaste}
            onKeyDown={(e) => {
                let newCode = verifyCode.slice();
                let newInput;
                if (isNumber(e.key)) {
                    //only allow input to be number
                    newInput = e.key;
                    if (verifyCode[i] && i < 5) {
                        //Amend box that has value: if that box already has a number and it is not the last box,
                        //this key down aims to input value for the next one instead of current one
                        newCode[i + 1] = newInput;
                        if (i < 4) {
                            //if the inputting box is or before the second last box, focusing on the next box will have bug
                            ref[i + 2].current.focus();
                        }
                        if (i === 4) {
                            ref[i + 1].current.focus(); //if the inputting box is the second last one, this key down is aiming at the last box
                            //thus focusing on the next box is already the last one
                        }
                    } else {
                        newCode[i] = newInput; //if the input box is empty, allow the value to be changed according to index of array
                        if (i < 5) {
                            //while the inputbox is not the last one, focus on the last one
                            ref[i + 1].current.focus();
                        }
                        if (
                            i === 5 &&
                            document.activeElement === inputRef6.current &&
                            verifyCode[5]
                        ) {
                            return;
                        }
                    }
                } else if (e.key === "Backspace") {
                    //only allow number or backspace
                    newInput = "";
                    newCode[i] = newInput;
                    if (i > 0) {
                        ref[i - 1].current.focus(); //long as box where we delete value from is not the first one, focusing on the previous one
                    }
                }

                setVerifyCode(newCode);
            }}></FormField>
    ));

    useEffect(() => {
        ref[0]?.current?.focus();
        // eslint-disable-next-line
    }, []);

    return (
        <>
            <div
                value={verifyCodeStr}
                style={{ height: "30%", paddingTop: "17.5px" }}
                className={fieldStyle.boxWrap}>
                {field}
            </div>

            <ErrorMsg>{error}</ErrorMsg>
            <FwdButton
                style={{ width: "100%" }}
                onClick={() => {
                    handleConfirm();
                    setVerifyCode(initialState);
                    setFocus(focus + 1);
                }}>
                {t("VERIFY_FILED.CONFIRM")}
            </FwdButton>
            <div className={fieldStyle.resend}>
                {showResend ? (
                    <div
                        className={classes.forgotPW}
                        style={{ marginBottom: 0 }}
                        onClick={() => {
                            setShowResend(!showResend);
                            setVerifyCode(initialState);
                            handleResend();
                            setFocus(focus + 1);
                        }}>
                        {t("VERIFY_FILED.SEND_CODE")}
                    </div>
                ) : (
                    <Timer t={t} showResend={() => setShowResend(true)} />
                )}
            </div>
        </>
    );
}
