import React, { useState, useEffect } from "react";

const Timer = ({ showResend, t }) => {
    const [time, setTime] = useState(30);

    useEffect(() => {
        const loop = setInterval(() => {
            if (time > 0) {
                setTime(time - 1);
            }
        }, 1000);
        return () => clearInterval(loop);
    }, [time]);

    if (time === 0) {
        //props function making verifyForm show the resend button
        setTimeout(() => showResend(), 1000);
    }

    return (
        <div
            style={{
                fontWeight: 700,
                marginBottom: "10px",
                color: "grey",
            }}>
            {t("VERIFY_FILED.RESEND.PREFIX")} {time} {t("VERIFY_FILED.RESEND.SUFFIX")}
        </div>
    );
};

export default Timer;
