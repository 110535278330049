import axios from "axios";

const call = (method, url, headers, body) => {
    console.log("call:", method, url, headers, body);

    return axios({
        method: method,
        url: url,
        headers: headers,
        data: body,
    })
        .then(function (response) {
            // handle success
            console.log(response);
            return response.data;
        })
        .catch(function (error) {
            // handle error
            if (error?.response) {
                console.log(error.response);
                return error.response.data;
            }
            console.log(error);
            alert(error);
            return error;
        });
};

export const doGet = async (url, headers, requestParams) => {
    let api = new URL(url);
    let search_params = api.searchParams;

    for (const [key, value] of Object.entries(requestParams)) {
        search_params.set(key, value);
        console.log(`${key}: ${value}`);
    }
    api.search = search_params.toString();

    return await call("GET", api.toString(), headers, requestParams);
};

export const doFormPost = async (url, headers, body) => {
    const formHeader = {
        "Content-Type": "application/x-www-form-urlencoded",
    };
    const urlencoded = new URLSearchParams();
    for (const [key, value] of Object.entries(body)) {
        urlencoded.append(key, value);
        console.log(`${key}: ${value}`);
    }

    return await call("POST", url, { ...formHeader, ...headers }, urlencoded);
};

export const doJsonPost = async (url, headers, body) => {
    const jsonHeader = {
        "Content-Type": "application/json",
    };
    return await call(
        "POST",
        url,
        { ...jsonHeader, ...headers },
        JSON.stringify(body),
    );
};
