const lang = {
    LOGIN_PAGE: {
        LOGIN_TITLE: "Login to FWD",
        FORGOT_PW_TITLE: "Recall Advisor ID or Reset Password",
        CHANGE_PW_TITLE: "Forgot Advisor ID or Password",
        SET_PW_TITLE: "Set Login Password",
        REG_TITLE: "Account Activation",
    },
    LOGIN_FORM: {
        ID: "Advisor ID",
        PASSWORD: "Password",
        FORGOT_PW: "Forgot Advisor ID/password?",
        LOGIN: "Login",
        MSG: {
            NO_ID: "Please input Advisor ID",
            NO_PW: "Please input Password",
            NOT_VALID:
                "Password must be at least 8 characters, includes at least a lowercase letter, an uppercase letter, a number, a symbol",
            INCORRECT: "Incorrect Advisor ID/ password!",
            LOGIN_SUCCESS: "Successfully Login",
        },
    },
    REG_FORM: {
        ENTER_OTP: "Enter verification code",
        SENT: "We have sent you 6-digit verification code to number",
        NOT_SENT: `Please click "Send Code" button to retrieve your "One-time passcode" to set up your own password`,
        MSG: {
            SIX_DIGIT: "Please input 6 digits",
            WRONG_CODE: "Wrong Passcode",
        },
    },
    FORGET_PW_FORM: {
        OTP_TIP:
            "Enter your registered mobile number and we will send an One-time Password (OTP) to you.",
        PHONE: "Mobile phone",
        SEND: "Send OTP",
        BACK: "Back to login",
    },
    CHANGE_PW_FORM: {
        SENT: "We have sent you 6-digit verification code to number",
        ENTER_OTP: "Enter verification code",
        BACK: "Back to login",
        VERIFIED: "Your account has been verified.",
        YOUR_ID: "Your Advisor ID",
        NEW_PASSWORD_MSG:
            "If you forgot your password, you can reset by creating new password with following instructions",
        LEAST_CHAR: "At least 8 characters",
        COMBINATION:
            "With a combination of uppercase, lowercase, number and special characters",
        NO_OLD: "Please do not use your old password",
        NEW_PW: "New password",
        CONFIRM_PW: "Confirm new password",
        CHANGE_PW: "Change Password",
        RESET_SUCCESS: "Your password has been reset successfully!",
        MSG: {
            NO_PW: "Please input your new password.",
            NOT_VALID:
                "Your password must be a combination of upper & lower case characters, non-alphabetic characters (e.g. !,$,#,%, etc.) and numeric digits.",
            NOT_MATCH: "Please make sure your passwords match.",
            WARN: "Please make sure your new password follow our instructions.",
        },
    },
    SET_PW_FORM: {
        SET_PW_TITLE:
            "Please activate your account by setting your first password",
        LEAST_CHAR: "At least 8 characters",
        COMBINATION:
            "With a combination of uppercase, lowercase, number and special characters",
        NO_OLD: "Please do not use your old password or lastname",
        NEW_PW: "New password",
        CONFIRM_PW: "Confirm new password",
        SET_PW: "Set Password",
        MSG: {
            NO_PW: "Please input your new password.",
            NOT_VALID:
                "Your password must be a combination of upper & lower case characters, non-alphabetic characters (e.g. !,$,#,%, etc.) and numeric digits.",
            NOT_MATCH: "Please make sure your passwords match.",
            WARN: "Please make sure your new password follow our instructions.",
            FAIL: "Activation fail, please try again",
            ACTIVATED: "Account activated, redirecting to login...",
        },
    },
    SELECT_USER_FORM: {
        AGENT_CODE: "Agent Code",
        SEND: "Send OTP",
        BACK: "Back to login",
    },
    VERIFY_FILED: {
        CONFIRM: "Confirm",
        SEND_CODE: "Send Code",
        RESEND: {
            PREFIX: "Can resend after",
            SUFFIX: "seconds",
        },
    },
    APPS: {
        IOS_DOWNLOAD: "IOS Download",
        ANDROID_DOWNLOAD: "Android Download",
    },
};

const translated = { translation: lang };

export default translated;
