import styled from "@emotion/styled";

const row = "display: flex; flex-direction: row;";
const col = "display: flex; flex-direction: column;";

const Div = styled.div`
    ${(props) => {
        if (props.direction && props.direction === "row") return row;
        else if (
            (props.direction && props.direction === "col") ||
            props.direction === "column"
        )
            return col;
    }}
    display: flex;
    align-items: center;
    ${(props) => props.center && "justify-content: center;"}
    color: ${(props) => `${props.color}`};
`;

export default Div;
