import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Form, FwdButton } from "./index";
import { formStyles } from "./style/formStyle";
import { AuthSelector, AuthSlice } from "../modules/auth/";
import { sendSMSAsync } from "../modules/auth/slice";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";

export default function SelectUserForm({ onSwitchPage, onBack, t }) {
    const classes = formStyles();
    const dispatch = useDispatch();
    const { usernames, phoneNumber } = useSelector(
        AuthSelector.selectResetPwInfo,
    );

    const [username, setUsername] = useState("");
    const handleChange = (event) => {
        setUsername(event.target.value);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        dispatch(AuthSlice.clearResetPwError());
        dispatch(sendSMSAsync({ phoneNumber, agentCode: username }));
        onSwitchPage();
    };

    return (
        <Form className={classes.form} onSubmit={handleSubmit}>
            <FormControl variant="outlined" style={{ width: "100%" }}>
                <InputLabel id="demo-simple-select-outlined-label">
                    {t("SELECT_USER_FORM.AGENT_CODE")}
                </InputLabel>
                <Select
                    value={username}
                    onChange={handleChange}
                    label="Agent Code">
                    {usernames?.map((agent) => {
                        return (
                            <MenuItem
                                key={agent.agentCode}
                                value={agent.agentCode}>
                                {agent.agentCode}
                            </MenuItem>
                        );
                    })}
                </Select>
            </FormControl>
            <FwdButton type="submit" style={{ width: "100%" }}>
                {t("SELECT_USER_FORM.SEND")}
            </FwdButton>
            <div className={classes.backBtn} onClick={onBack}>
                {t("SELECT_USER_FORM.BACK")}
            </div>
        </Form>
    );
}
