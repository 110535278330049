import styled from "@emotion/styled";

const PageContainer = styled.div`
    width: 100vw;
    height: 100%;
    position: relative;
    justify-content: center;
    display: flex;
    flex-direction: column;
`;

export default PageContainer;
