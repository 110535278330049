import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { fetchApps } from "./appsAPI";

const initialState = {
    apps: [],
    status: "idle",
};

export const fetchAppsAsync = createAsyncThunk(
    "appsCenter/fetchApps",
    async () => {
        const response = await fetchApps();
        return response.apps;
    },
);

export const appsCenterSlice = createSlice({
    name: "appsCenter",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchAppsAsync.pending, (state) => {
                state.status = "loading";
            })
            .addCase(fetchAppsAsync.fulfilled, (state, action) => {
                state.status = "idle";
                state.apps = action.payload;
            });
    },
});

// Action creators are generated for each case reducer function
// export const {} = appsCenterSlice.actions;

export const selectApps = (state) => state.appsCenter.apps;

export default appsCenterSlice.reducer;
