import Button from "@material-ui/core/Button";
import { FWD_COLOR } from "../config";
import { withStyles } from "@material-ui/core/styles";

const FwdButton = withStyles({
    root: {
        display: "flex",
        alignSelf: "center",
        alignItems: "center",
        minWidth: 200,
        height: 50,
        justifyContent: "center",
        borderRadius: 8,
        borderWidth: 0,
        backgroundColor: FWD_COLOR.ORANGE,
        color: FWD_COLOR.WHITE,
        "&:hover": {
            backgroundColor: FWD_COLOR.ORANGE,
            color: FWD_COLOR.WHITE,
            opacity: 0.8,
        },
        "& span.MuiButton-label": {
            fontSize: 14,
            textTransform: "none",
        },
    },
    label: {
        textTransform: "capitalize",
    },
})(Button);

export default FwdButton;
