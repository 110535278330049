import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ErrorMsg, FwdButton, Form } from "./index";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { formStyles } from "./style/formStyle";
import { sendSMSAsync, setResetPwPhoneNumber } from "../modules/auth/slice";
import { AuthSelector } from "../modules/auth";

const ForgetPWForm = ({ onSubmit, onBack, t }) => {
    const classes = formStyles();
    const dispatch = useDispatch();
    const [phoneNumber, setPhoneNumber] = useState("");
    const { resetPwError } = useSelector(AuthSelector.selectResetStatus);
    const handleChange = (phone) => {
        setPhoneNumber(phone);
    };

    const handleResetPw = (e) => {
        e.preventDefault();
        dispatch(setResetPwPhoneNumber(phoneNumber));
        dispatch(sendSMSAsync({ phoneNumber }));
        onSubmit();
    };

    return (
        <Form
            className={classes.form}
            onSubmit={(e) => {
                handleResetPw(e);
            }}>
            <div>{t("FORGET_PW_FORM.OTP_TIP")}</div>
            <div style={{ width: "100%" }}>
                <div className={classes.fieldName}>
                    {t("FORGET_PW_FORM.PHONE")}
                </div>
            </div>

            {/* need check validity? */}
            <div>
                <PhoneInput
                    id={"phoneInput"}
                    country={"kh"}
                    enableAreaCodes={true}
                    enableSearch={true}
                    disableSearchIcon={false}
                    value={phoneNumber}
                    onChange={(phone) => handleChange(phone)}
                />
            </div>
            <ErrorMsg>{resetPwError}</ErrorMsg>
            <FwdButton type="submit" style={{ width: "100%" }}>
                {t("FORGET_PW_FORM.SEND")}
            </FwdButton>
            <div className={classes.backBtn} onClick={onBack}>
                {t("FORGET_PW_FORM.BACK")}
            </div>
        </Form>
    );
};

export default ForgetPWForm;
