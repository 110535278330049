import React, { useState, useEffect } from "react";
import { Div, ErrorMsg, FwdButton, Form, VerifyField, Loading } from "./index";
import { formStyles } from "./style/formStyle";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useDispatch, useSelector } from "react-redux";
import { pwValidation } from "../validation";

import { AuthSlice, AuthSelector } from "../modules/auth";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import IconButton from "@material-ui/core/IconButton";
import Input from "@material-ui/core/Input";
import InputAdornment from "@material-ui/core/InputAdornment";
import { FWD_COLOR } from "../config";

const ChangePwForm = ({ onBack, t, selectUser }) => {
    const dispatch = useDispatch();
    const classes = formStyles();
    const above600px = useMediaQuery("(min-width:600px)");
    const resetPwInfo = useSelector(AuthSelector.selectResetPwInfo);
    const { verifySMSSuccess, resetPwError, resetPwSuccess } = useSelector(
        AuthSelector.selectResetStatus,
    );
    const authLoading = useSelector(AuthSelector.selectAuthLoading);

    const [newPw, setNewPw] = useState({
        pw: "",
        confirmPw: "",
        showPassword: false,
    });

    let onScreenPhoneNum = `${resetPwInfo?.phoneNumber.slice(0, -4)}`;
    if (onScreenPhoneNum) {
        onScreenPhoneNum += "XXXX";
    }

    const [errorMsg, setErrorMsg] = useState("");
    const [page, switchPage] = useState("verifyOTP");
    const [isPwValid, setIsPwValid] = useState(false);

    useEffect(() => {
        dispatch(AuthSlice.clearResetPwError());

        if (verifySMSSuccess) {
            switchPage("resetPw");
        }
        // eslint-disable-next-line
    }, [verifySMSSuccess]);

    useEffect(() => {
        if (resetPwError === "More than one record") {
            selectUser();
        }
    }, [resetPwError, selectUser]);

    const togglePwVisibility = () => {
        setNewPw({
            ...newPw,
            showPassword: !newPw.showPassword,
        });
    };

    const inputPw = (e) => {
        setNewPw({
            ...newPw,
            pw: e.currentTarget.value,
        });
    };

    const validateInputPw = () => {
        const pw = newPw.pw;
        const confirmPw = newPw.confirmPw;

        if (!pw) {
            setErrorMsg(`${t("CHANGE_PW_FORM.MSG.NO_PW")}`);
            return setIsPwValid(false);
        }

        if (!pwValidation(pw)) {
            setErrorMsg(`${t("CHANGE_PW_FORM.MSG.NOT_VALID")}`);
            return setIsPwValid(false);
        }

        if (confirmPw === "") {
            setErrorMsg("");
            return setIsPwValid(false);
        }

        if (pwValidation(pw) && pw === confirmPw) {
            return setIsPwValid(true);
        }
    };

    const inputConfirmPw = (e) => {
        setNewPw({
            ...newPw,
            confirmPw: e.currentTarget.value,
        });
    };

    const confirmInputPwMatch = () => {
        const pw = newPw.pw;
        const confirmPw = newPw.confirmPw;
        if (pw !== confirmPw) {
            setErrorMsg(`${t("CHANGE_PW_FORM.MSG.NOT_MATCH")}`);
            return setIsPwValid(false);
        }
        if (pwValidation(pw) && pw === confirmPw) {
            setErrorMsg("");
            return setIsPwValid(true);
        }
    };

    const handleResend = () => {
        const phoneNumber = resetPwInfo?.phoneNumber;
        setErrorMsg("");
        dispatch(AuthSlice.clearResetPwError());
        dispatch(AuthSlice.sendSMSAsync({ phoneNumber }));
    };

    const handleConfirm = async (passCode) => {
        const stateToken = resetPwInfo.stateToken;
        dispatch(AuthSlice.clearResetPwError());
        dispatch(AuthSlice.verifySMSFactorAsync({ stateToken, passCode }));
        if (!verifySMSSuccess) {
            return;
        }
        switchPage("resetPw");
    };

    const handleChangePw = (e) => {
        e.preventDefault();
        if (isPwValid && newPw.pw === newPw.confirmPw) {
            const stateToken = resetPwInfo.stateToken;
            const newPassword = newPw.pw;
            setTimeout(() => {
                dispatch(AuthSlice.resetPwAsync({ stateToken, newPassword }));
            }, 200);
        } else {
            setErrorMsg(`${t("CHANGE_PW_FORM.MSG.WARN")}`);
        }
    };

    const handleBack = () => {
        dispatch(AuthSlice.clearResetPwState());
        onBack();
    };

    return (
        <>
            {authLoading === "loading" && <Loading />}
            {page === "verifyOTP" && (
                <Form style={{ height: "auto" }} className={classes.form}>
                    <div>
                        {t("CHANGE_PW_FORM.SENT")}
                        <span className={classes.fieldName}>
                            : <br></br>
                            {onScreenPhoneNum}
                        </span>
                    </div>
                    <div className={fieldStyle.padding}>
                        {t("CHANGE_PW_FORM.ENTER_OTP")}:
                    </div>
                    <VerifyField
                        t={t}
                        onResend={handleResend}
                        onConfirm={handleConfirm}
                        error={errorMsg + resetPwError}
                    />
                    <Div
                        style={{
                            width: "100%",
                            justifyContent: "center",
                            paddingTop: "20px",
                        }}
                        className={classes.forgotPW}
                        onClick={onBack}>
                        {t("CHANGE_PW_FORM.BACK")}
                    </Div>
                </Form>
            )}
            {page === "resetPw" && verifySMSSuccess && (
                <Form
                    style={above600px ? formStyle : mobileFormStyle}
                    className={classes.form}>
                    <div
                        style={
                            above600px
                                ? { width: "40%", height: "100%" }
                                : mobileDivStyle
                        }>
                        <div>
                            {t("CHANGE_PW_FORM.VERIFIED")}
                            <br></br>
                            <div className={classes.fieldName}>
                                {t("CHANGE_PW_FORM.YOUR_ID")}:{" "}
                                <span>{`${resetPwInfo?.username}`}</span>
                            </div>
                        </div>
                        <div>{t("CHANGE_PW_FORM.NEW_PASSWORD_MSG")}:</div>
                        <div style={bulletPoints}>
                            • {t("CHANGE_PW_FORM.LEAST_CHAR")}
                            <br></br>• {t("CHANGE_PW_FORM.COMBINATION")}
                            <br></br>•{t("CHANGE_PW_FORM.NO_OLD")}
                        </div>
                    </div>

                    <div
                        style={
                            above600px
                                ? { width: "60%", height: "100%" }
                                : mobileDivStyle
                        }>
                        <div
                            className={classes.fieldName}
                            style={{ fontSize: "16px" }}>
                            {t("CHANGE_PW_FORM.NEW_PW")}
                            <Input
                                style={fieldStyle}
                                type={newPw.showPassword ? "text" : "password"}
                                value={newPw.value}
                                onChange={(e) => {
                                    inputPw(e);
                                }}
                                onBlur={() => {
                                    validateInputPw();
                                }}
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={togglePwVisibility}>
                                            {newPw.showPassword ? (
                                                <Visibility />
                                            ) : (
                                                <VisibilityOff />
                                            )}
                                        </IconButton>
                                    </InputAdornment>
                                }></Input>
                        </div>
                        <div
                            className={classes.fieldName}
                            style={{ fontSize: "16px" }}>
                            {t("CHANGE_PW_FORM.CONFIRM_PW")}
                            <Input
                                style={fieldStyle}
                                type={newPw.showPassword ? "text" : "password"}
                                onChange={(e) => {
                                    inputConfirmPw(e);
                                }}
                                onBlur={() => {
                                    confirmInputPwMatch();
                                }}
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={togglePwVisibility}>
                                            {newPw.showPassword ? (
                                                <Visibility />
                                            ) : (
                                                <VisibilityOff />
                                            )}
                                        </IconButton>
                                    </InputAdornment>
                                }></Input>
                        </div>
                        <ErrorMsg>{errorMsg + resetPwError}</ErrorMsg>
                        <FwdButton
                            onClick={(e) => {
                                handleChangePw(e);
                            }}
                            style={{ width: "100%" }}>
                            {t("CHANGE_PW_FORM.CHANGE_PW")}
                        </FwdButton>
                        <Div
                            style={{
                                width: "100%",
                                justifyContent: "center",
                                paddingTop: "20px",
                            }}
                            className={classes.forgotPW}
                            onClick={onBack}>
                            {t("CHANGE_PW_FORM.BACK")}
                        </Div>
                    </div>
                </Form>
            )}
            {page === "resetPw" && resetPwSuccess && (
                <div style={absoluteStyle} className={classes.form}>
                    {t("CHANGE_PW_FORM.RESET_SUCCESS")}
                    <Div
                        style={{
                            width: "100%",
                            justifyContent: "center",
                            paddingTop: "20px",
                        }}
                        className={classes.forgotPW}
                        onClick={handleBack}>
                        {t("CHANGE_PW_FORM.BACK")}
                    </Div>
                </div>
            )}
        </>
    );
};

export default ChangePwForm;

const formStyle = {
    height: "auto",
    flexDirection: "row",
    maxHeight: "400px",
    minWidth: "600px",
};

const mobileFormStyle = {
    height: "auto",
    flexDirection: "column",
    maxHeight: "none",
    maxWidth: "90vw",
    overflow: "auto",
    paddingTop: "20px",
    marginBottom: "20px",
};

const mobileDivStyle = {
    width: "100%",
};

const fieldStyle = {
    width: "100%",
    height: "50px",
    padding: "10px 5px",
    "&:focus": {
        outline: "none",
        border: `solid 1px ${FWD_COLOR.ORANGE}`,
    },
};

const bulletPoints = {
    color: "#878787",
};

const absoluteStyle = {
    minHeight: "none",
    maxHeight: "20vh",
    width: "30vw",
    minWidth: "250px",
    position: "fixed",
    boxShadow: " 1px 1px 5px black",
    borderRadius: "10px",
    padding: "20px",
    textAlign: "center",
    alignSelf: "center",
    justifySelf: "center",
    top: "40VH",
};
